/* Global Styles */
body {
  font-family: 'Roboto', Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 250px;
  background-color: #f0f2f5;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.content {
  flex-grow: 1;
  padding: 20px;
}

/* Login Page Styles */
.login-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin: 20px;
}

.login-container h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.login-container input {
  display: block;
  width: calc(100% - 20px);
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-container button {
  width: 100%;
  padding: 12px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #003d80;
}

.login-container p {
  color: red;
  font-size: 14px;
}

/* Manage Images Styles */
.manage-images-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 1000px;
  width: 100%;
  margin: 20px auto;
}

.manage-images-container h1 {
  font-size: 30px;
  margin-bottom: 25px;
  color: #333;
  text-align: center;
}

.manage-images-container form {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.manage-images-container .file-label {
  cursor: pointer;
  padding: 12px 24px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  margin-right: 15px;
  transition: background-color 0.3s ease;
}

.manage-images-container .file-label:hover {
  background-color: #0056b3;
}

.manage-images-container input[type='file'] {
  display: none;
}

.manage-images-container button {
  padding: 12px 24px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.manage-images-container button:hover {
  background-color: #218838;
}

.delete-selected-button {
  margin-top: 10px;
  background-color: #dc3545;
  transition: background-color 0.3s ease;
}

.delete-selected-button:hover {
  background-color: #c82333;
}

.build-button {
  margin-top: 10px;
  margin-left: 15px;
  background-color: #17a2b8;
  transition: background-color 0.3s ease;
}

.build-button:hover {
  background-color: #138496;
}

.error {
  color: #dc3545;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  background-color: #f8d7da;
  padding: 10px;
  border-radius: 5px;
}

.success {
  color: #28a745;
  font-size: 16px;
  margin-top: 10px;
  background-color: #d4edda;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.messages {
  margin-bottom: 20px;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

/* Image Grid Styles */
.image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.image-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
}

.image-item.selected {
  transform: scale(0.95);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  border: 2px solid #007bff;
}

.image-item img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  display: block;
  border-radius: 8px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 800px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .manage-images-container {
    padding: 20px;
  }

  .image-item img {
    height: 120px;
  }

  .storage-info {
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    margin-top: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .storage-info h2 {
    font-size: 26px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .storage-info p {
    font-size: 16px;
    color: #555;
    margin: 10px 0;
  }
  
}
